<template>
  <q-dialog v-model="show" :persistent="submitting">
    <q-card style="width: 820px; max-width: 75vw">
      <q-card-section>
        <div class="text-h6">
          {{ $t('change_member_role.title') }}
        </div>
      </q-card-section>
      <q-card-section>
        <div class="text-body">
          {{ $t('change_member_role.message') }}
        </div>
      </q-card-section>
      <q-card-section>
        <q-btn-toggle
          v-model="role"
          :options="roleToggleOptions"
          spread
        />
      </q-card-section>
      <template v-if="roleHasChanged">
        <m-banner
          v-if="role === 'middleoffice' && orgPaymentsDisabled"
          :title="$t('change_member_role.warnings.disabled_payments_middleoffice.title')"
          borderless
          color="red"
          icon="fas fa-exclamation-triangle"
        >
          <template v-slot:message>
            <div class="text-body2" style="white-space: pre-line">
              {{ $t('change_member_role.warnings.disabled_payments_middleoffice.message') }}
            </div>
            <div class="text-body2">
              <strong>{{ orgPaymentOptions.join(', ') }}</strong>
            </div>
          </template>
        </m-banner>
        <m-banner
          v-else-if="role === 'member' && orgPaymentsDisabled"
          :title="$t('change_member_role.warnings.disabled_payments_member.title')"
          :message="$t('change_member_role.warnings.disabled_payments_member.message')"
          borderless
          color="warning"
          icon="fas fa-exclamation-triangle"
        />
        <m-banner
          v-else-if="role === 'read-only'"
          :title="$t('change_member_role.warnings.readonly_member.title')"
          :message="$t('change_member_role.warnings.readonly_member.message')"
          borderless
          color="warning"
          icon="fas fa-exclamation-triangle"
        />
      </template>
      <q-card-actions align="right">
        <q-btn
          :label="$t('cancel')"
          @click="show = false"
        />
        <q-btn
          color="primary"
          :label="$t('save.save')"
          :disabled="!role || !roleHasChanged || submitting"
          :loading="submitting"
          @click="saveChange"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { MBanner } from 'components/'
export default {
  name: 'MemberChangeRoleModal',
  components: { MBanner },
  props: {
    member: Object,
    organisation: Object,
    value: Boolean
  },
  data () {
    return {
      role: null,
      submitting: false
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    currentRole () {
      if (!this.member || !this.member.roles) return null
      if (!this.member.roles.includes('selfbooker')) return 'read-only'
      if (this.member.roles.includes('middleoffice.outsource')) return 'middleoffice_outsource'
      return this.member.roles.includes('middleoffice') ? 'middleoffice' : 'member'
    },
    roleHasChanged () {
      return this.role !== this.currentRole
    },
    orgPaymentsDisabled () {
      if (!this.organisation) return false

      const { payments_disabled } = this.organisation.attributes
      return !!payments_disabled
    },
    orgPaymentOptions () {
      let payments = ['Card', 'Paypal']
      if (!this.organisation) return payments

      const { has_account } = this.organisation
      if (has_account === true) payments.push('Account')
      return payments
    },
    orgIsOnwardTravel () {
      if (!this.organisation) return false

      return this.organisation.is_onward_travel || false
    },
    roleToggleOptions () {
      const mainOptions = [
        { label: this.$tc('read_only'), value: 'read-only' },
        { label: this.$tc('member'), value: 'member' },
        { label: this.$tc('middleoffice'), value: 'middleoffice' }
      ]

      if (this.orgIsOnwardTravel) {
        mainOptions.push({
          label: this.$tc('middleoffice_outsource'),
          value: 'middleoffice_outsource'
        })
      }

      return mainOptions
    }
  },
  watch: {
    member: {
      handler () {
        this.role = this.currentRole
      },
      immediate: true
    },
    role () {
      if (this.roleHasChanged) {
        console.log(this.member.display_name, 'change role', this.role)
      }
    }
  },
  methods: {
    saveChange () {
      if (!this.roleHasChanged) {
        this.show = false
        return
      }

      this.submitting = true

      this.$store.dispatch('members/changeRole', {
        user: this.member,
        role: this.role
      })
        .then(resolve => {
          this.$q.notify({
            message: this.$t('role_changed_to', { role: resolve.newRole }),
            color: 'positive',
            icon: 'mdi-check'
          })
          if (resolve.rolesArray?.length) this.member.roles = resolve.rolesArray
          this.show = false
        })
        .catch(e => {
          console.error(e)
          this.$q.notify({
            message: this.$t('error.role_not_changed'),
            color: 'negative',
            icon: 'clear'
          })
        })
        .finally(() => {
          this.submitting = false
        })
    }
  }
}
</script>
